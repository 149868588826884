import React, { Component } from 'react'
import styled from 'styled-components'
import { PhotoSwipeGallery } from 'react-photoswipe'
import Carousel, { Modal, ModalGateway, FooterCaption } from 'react-images'

import Button from '../components/button'
import { ChevronRight } from 'react-feather'

const PhotoGallerySection = styled.div`
  text-align: center;
`
const GalleryHeading = styled.h2`
  margin-bottom: 0.35em;
  font-size: calc(0.75vw + 1.2em);
`
const GalleryDescription = styled.p`
  max-width: 860px;
  color: #555;
  margin: 0 auto;
  margin-bottom: 1.5em;
`

const PhotoTitle = styled.p`
  font-size: 90%;
`

const InstructionalText = styled.p`
  text-align: center;
  color: #717171;
  font-size: 0.8em;
  font-style: italic;
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .pswp-thumbnails {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .pswp-thumbnail {
    flex: 0 0 12.5%;
    padding: 7px;
    img {
      box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25);
      border-radius: 2px;
    }
    @media (max-width: 992px) {
      flex: 0 0 33.333333333%;
    }
    @media (max-width: 767px) {
      flex: 0 0 50%;
    }
    img {
      margin: 0;
    }
  }
`

const ImageWrapper = styled.div`
  flex: 0 0 12.5%;
  padding: 7px;
  img {
    box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25);
    border-radius: 2px;
    &:hover {
      box-shadow: 0 7px 15px -2px rgba(50, 50, 93, 0.3);
    }
  }
  @media (max-width: 992px) {
    flex: 0 0 33.333333333%;
  }
  @media (max-width: 767px) {
    flex: 0 0 50%;
  }
  img {
    margin: 0;
  }
`

class PhotoGalleryComponent extends Component {
  state = {
    selectedIndex: 0,
    lightboxIsOpen: false,
  }
  toggleLightbox = (selectedIndex) => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }))
  }

  render() {
    const { isLoading } = this.props
    const { selectedIndex, lightboxIsOpen } = this.state

    let galleryAll = this.props.gallery
    if (this.props.gallery.length > 0) {
      galleryAll = this.props.gallery[0]
    }
    const { showTitles } = galleryAll
    let items = [] // initialize array
    {
      galleryAll.photos.map((item, i) => {
        let tempItem = {
          id: item.id,
          title: item.title,
          caption: 'US Quality Construction',
          src: item.fluid.src,
          thumbnail: item.fixed.src,
          w: 1200,
          h: 900,
          alt: item.title ? item.title : 'US Quality Construction',
        }
        items.push(tempItem)
      })
    }

    return (
      <div>
        <>
          <PhotoGallerySection className="container">
            <GalleryHeading>
              {galleryAll.title ? galleryAll.title : 'No Gallery Title'}
            </GalleryHeading>
            {galleryAll.description && galleryAll.description.description ? (
              <GalleryDescription>
                {galleryAll.description.description}
              </GalleryDescription>
            ) : null}

            <FlexContainer className="gallerythumbs">
              {items.map(({ thumbnail, caption, id, alt, title }, j) => (
                <ImageWrapper onClick={() => this.toggleLightbox(j)} key={id}>
                  <img
                    alt={alt}
                    src={thumbnail}
                    css={{
                      cursor: 'pointer',
                    }}
                  />
                  {showTitles && <PhotoTitle> {title}</PhotoTitle>}
                </ImageWrapper>
              ))}
            </FlexContainer>
            <InstructionalText>Tap images to enlarge</InstructionalText>
            <Button
              to="/projects/"
              className="mt-5-i md:hidden lg:hidden xl:hidden"
            >
              <span className="flex items-center justify-center babtn">
                See More Recent Projects <ChevronRight />
              </span>
            </Button>
            <ModalGateway>
              {lightboxIsOpen && !isLoading ? (
                <Modal onClose={this.toggleLightbox}>
                  <Carousel
                    currentIndex={selectedIndex}
                    frameProps={{ autoSize: 'height' }}
                    views={items}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </PhotoGallerySection>
        </>
      </div>
    )
  }
}

export default PhotoGalleryComponent
